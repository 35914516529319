<template>
  <div>
    <div class="e-breadcrumb">
      <span @click="closeEvent">会员卡设置</span>
      > 编辑
    </div>
    <div class="order-table-wrapper">
      <div class="e-card" style="padding:30px">
        <el-form ref="form" label-width="230px">
          <el-form-item size="small" label="卡类编号:" required>
            <el-input v-model="item.cardNo" placeholder="请输入卡类编号" style="width: 200px;"></el-input>
            <span style="margin-left: 10px">卡类编号限定从1开始的任意整数，且不能重复</span>
          </el-form-item>
          <el-form-item size="small" label="卡类名称:" style="margin-top: 20px;height: 40px" required>
            <el-input v-model="item.cardName" placeholder="请输入卡类名称" style="width: 200px;"></el-input>
            <div style="position:relative;left: 205px;top: -50px">
              <p class="p-text">比如贵宾卡、金卡、银卡、会员卡等等，强烈建议</p>
              <p class="p-text">设置一种卡类编号为9，卡类名称为"散客"的虚拟</p>
              <p class="p-text">会员卡，用来保存没有开卡的顾客的资料</p>
            </div>
          </el-form-item>
          <el-form-item size="small" label="卡类型:" required>
            <el-radio-group v-model="item.cardType">
              <el-radio :label="0">储值卡</el-radio>
              <el-radio :label="1">计次卡</el-radio>
              <el-radio :label="2">套餐卡</el-radio>
              <el-radio :label="3">年卡</el-radio>
              <el-radio :label="4">资格卡(现金消费)</el-radio>
              <el-radio :label="'5'">散客卡</el-radio>
            </el-radio-group>
            <div style="position:relative;left: 625px;top: -61px">
              <p class="p-text">储值卡:可充值享受特定折扣的会员卡</p>
              <p class="p-text">计次卡:按次折扣的会员卡</p>
              <p class="p-text">套餐卡:可以用来购买套餐的储值卡</p>
              <p class="p-text">资格卡:不可充值，购买后可享受特定折扣的会员卡</p>
            </div>
            <div style="background: #e4e4e4;padding: 10px;width: 600px;margin-top: -70px">
              <el-form-item size="small" label-width="110px" style="margin-top: 15px" label="开卡成本:" required>
                <el-input v-model="item.openCardCost" placeholder="请输入开卡成本" style="width: 300px"></el-input>
              </el-form-item>

              <el-form-item size="small" label-width="110px" label="开卡金额:" required>
                <el-input v-model="item.openCardAmount" placeholder="请输入开卡金额" style="width: 300px"></el-input>
              </el-form-item>
              <el-form-item size="small" label-width="110px" label="最低充值金额:" required>
                <el-input v-model="item.minimumRechargeAmount" placeholder="请输入最低充值金额" style="width: 300px"></el-input>
              </el-form-item>

              <el-form-item size="small" label-width="110px" label="充值金额提醒:" required>
                <el-input v-model="item.rechargeAmountReminder" placeholder="请输入充值金额提醒" style="width: 300px"></el-input>
              </el-form-item>

              <el-form-item size="small" label-width="110px" label="" required>
                <el-checkbox-group v-model="cardOption">
                  <el-checkbox style="width: 250px;" v-for="item in cardOptionList" :label="item.value"
                               :key="item.value">{{item.name}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
          </el-form-item>
          <hr style="margin-bottom: 15px">
          <el-form-item size="small" label="项目折扣:" required>
            <el-input v-model="item.itemDiscount" placeholder="请输入项目折扣" style="width:200px;"></el-input>
            <span style="margin-left: 10px">折扣请输入一个数字，如9表示九折，8.5表示八五折，0表示没有折扣</span>
          </el-form-item>
          <el-form-item size="small" label="卖品折扣:" required>
            <el-input v-model="item.productDiscount" placeholder="请输入卖品折扣" style="width:200px;"></el-input>
            <span style="margin-left: 10px">折扣请输入一个数字，如9表示九折，8.5表示八五折，0表示没有折扣</span>
          </el-form-item>

          <el-form-item size="small" style="height: 32px" label="金额提醒短信:" required>
            <el-select v-model="item.amountReminderSms" style="width:200px;" placeholder="请选择">
              <el-option
                v-for="item in arsList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div style="position:relative;left: 202px;top: -41px">
              <p class="p-text">类似于银行或者移动公司那种交易，充值后自动发送短信提醒顾客金额变</p>
              <p class="p-text">动情况的功能，这里可根据具体情况设置发送或者不发送</p>
            </div>
          </el-form-item>

          <el-form-item size="small" label="是否扣信息费:" required>
            <el-select v-model="item.isSmsFee" style="width:200px;" placeholder="请选择">
              <el-option
                v-for="item in sfList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item size="small" label="卡号生成:" required>
            <el-select v-model="item.cardNumberType" style="width:200px;" placeholder="请选择">
              <el-option
                v-for="item in cntList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span>
              卡上有卡号或者编号请选择手工输入，反之请选择自动生成
            </span>
          </el-form-item>

          <el-form-item size="small" label="是否办理:" required>
            <el-select v-model="item.isHandle" style="width:200px;" placeholder="请选择">
              <el-option
                v-for="item in ihList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item size="small" label="卡过期支持消费:" required>
            <el-radio-group v-model="item.overdueConsumption">
              <el-radio :label="0">支持</el-radio>
              <el-radio :label="1">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item size="small" label="是否允许跨店消费:" required>
            <el-radio-group v-model="item.crossStoreConsumption">
              <el-radio :label="0">支持</el-radio>
              <el-radio :label="1">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item size="small" label="是否允许跨店充值:" required>
            <el-radio-group v-model="item.crossStoreRecharge">
              <el-radio :label="0">支持</el-radio>
              <el-radio :label="1">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item size="small" label="门店关闭后是否允许充值:" required>
            <el-radio-group v-model="item.storeClosedRecharge">
              <el-radio :label="0">支持</el-radio>
              <el-radio :label="1">不支持</el-radio>
            </el-radio-group>
            <span style="margin-left: 10px">例如在门店A办理了此卡，门店A关闭后，此卡是否允许在别的门店充值</span>
          </el-form-item>

          <el-form-item size="small" label="卡金赠送仅合并使用:" required>
            <el-radio-group v-model="item.kaginGiveMerge">
              <el-radio :label="0">支持</el-radio>
              <el-radio :label="1">不支持</el-radio>
            </el-radio-group>
            <span style="margin-left: 10px">允许后，卡金和赠送金按结余比例扣减，顾客端仅能看到总额</span>
          </el-form-item>
          <el-form-item size="small" label="赠送金限制项目:" required>
            <el-button size="small" type="primary" @click="siView">选择服务项目</el-button>
          </el-form-item>

          <el-form-item size="small" label="赠送金是否可打折:" required>
            <el-checkbox v-model="checked">可打折</el-checkbox>
            <span>设置为不打折时，赠送金额支付的部分不打折，该项不勾选时生成正常订单</span>
          </el-form-item>

          <el-form-item size="small" label="赠送金不超过总金额的:" required>
            <el-input v-model="item.noExceed" placeholder="请输入数值" style="width:200px;"></el-input>
            <span style="margin-left: 10px">%  例如账单总额是100元，如果赠送金支付不超过25%，则赠送金最多支付20%</span>
          </el-form-item>

          <el-form-item size="small" label="卡内最低余额:" required>
            <el-input v-model="item.minimumBalance" placeholder="请输入数值" style="width: 200px"></el-input>
            <span style="margin-left: 10px">不可扣到低于设置的金额</span>
          </el-form-item>

          <el-form-item size="small" label="开卡当日消费不得超过总额的:" required>
            <el-input v-model="item.cdNotExceed" placeholder="请输入数值" style="width: 200px"></el-input>
            <span style="margin-left: 10px">%  例如设置80%，开卡总是1000，则当日最多消费800</span>
          </el-form-item>

          <el-form-item size="small" label="卡金支付额不得低于账单金额的:" required>
            <el-input v-model="item.noBelow" placeholder="请输入数值" style="width: 200px"></el-input>
            <span style="margin-left: 10px">%  例如账单金额200，设置值为60%，则卡金至少支付(含赠送金)120</span>
          </el-form-item>

          <el-form-item size="small" label="有效期:" required>
            <el-input v-model="item.expiryDate" placeholder="请输入有效期" style="width: 200px"></el-input>
            <span style="margin-left: 10px">输入有效期月数，可不填</span>
          </el-form-item>

          <el-form-item size="small" label="不享受折扣支付方式:" required>
            <el-radio-group v-model="item.noDiscount">
              <el-radio :label="'0'">现金</el-radio>
              <el-radio :label="'1'">银联</el-radio>
              <el-radio :label="'2'">支付宝</el-radio>
              <el-radio :label="'3'">微信</el-radio>
              <el-radio :label="'4'">大众点评</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item size="small" label="适用门店:" required>
            <div style="width: 600px">
              <el-checkbox v-model="checkAll" @change="checkAllEvent">全选</el-checkbox>
              <div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>
              <template v-for="(sd,key) in cities">
                <div style="margin: 15px 0;"></div>
                <el-checkbox v-model="sd.checkAll" @change="handleCheckAllChange(key)">{{sd.name}}
                </el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="sd.checkedCities" @change="handleCheckedCitiesChange(key)">
                  <el-checkbox v-for="item in sd.list" :label="item.id" :key="item.id">{{item.departmentName}}
                  </el-checkbox>
                </el-checkbox-group>
                <div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>
              </template>
            </div>
          </el-form-item>

          <el-form-item size="small" label="备注:" required>
            <el-input type="textarea" placeholder="请输入备注" :rows="4" style="width: 300px"
                      v-model="item.remarks"></el-input>
          </el-form-item>

          <el-form-item size="small" label="图片:" required>
            <div class="graphic-description-value">
              <div>
                <el-upload
                  class="e-file-upload"
                  :action="Base_url+'/admin/uploadImg'"
                  :on-success="imageSuccess1"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                >
                  <el-image v-if="item.imgUrl"
                            style="width: 137px; height: 137px"
                            :src="item.imgUrl"
                            fit="contain"></el-image>
                  <div class="e-upload--text" v-if="!item.imgUrl">
                    <span class="e-upload--icon el-icon-plus " style="margin-top: 30px"></span>
                    <p>添加上传图片</p>
                  </div>
                  <p class="e-file-upload-delete" v-if="item.imgUrl"
                     @click.stop="item.imgUrl=''">
                    <i class="el-icon-delete"></i>
                  </p>
                </el-upload>
              </div>
            </div>
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveMbCard">确 定</el-button>
        </span>
      </div>
    </div>
    <el-dialog title="选择项目服务" :visible.sync="viewVisible" width="700px">
      <div style="border: 1px solid #ddd;padding: 10px">
        <el-row>
          <el-col :span="5">
            <span style="margin-top: 10px;font-weight:bold;font-size: 20px">
              服务项目:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="checkedItemIds">
                <el-checkbox style="margin-top: 3px" v-for="item in serviceItemList" :label="item.id" :key="item.id">
                  {{item.itemName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="border: 1px solid #ddd;padding: 10px;margin-top: 30px">
        <el-row>
          <el-col :span="5">
            <span style="margin-top: 10px;font-weight:bold;font-size: 20px">
              商品:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="checkedSkuIds">
                <el-checkbox style="margin-top: 3px" v-for="item in skuList" :label="item.id" :key="item.id">
                  {{item.skuName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>

      </div>

      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="viewVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setChecked">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>
<script>
  import {Base_url} from '../../../config/index'

  export default {
    data() {
      return {
        checked: false,
        cardOption: [],
        viewVisible: false,

        checkedItemIds: [],
        itemIds: [],
        serviceItemList: [],

        checkedSkuIds: [],
        skuIds: [],
        skuList: [],

        Base_url: '',

        cardId: '',
        item: {},

        checkAll: false,
        allCheckCities: [], // 全部选中得数据
        cities: {},
        checkTotal: 0,

        cardOptionList: [
          {
            name: '强制设置开卡密码 ( 开卡时必须设置密码 )',
            value: 0,
          }, {
            name: '低于开卡金额不允许开卡',
            value: 1,
          }, {
            name: '如已停用也不办理充值业务',
            value: 2,
          }
        ],
        arsList: [
          {
            label: '发送',
            value: 0
          }, {
            label: '不发送',
            value: 1
          }
        ],
        sfList: [
          {
            label: '扣除',
            value: 0
          }, {
            label: '不扣除',
            value: 1
          }
        ],
        cntList: [
          {
            label: '自动生成',
            value: 0
          }, {
            label: '手工输入',
            value: 1
          }
        ],
        ihList: [
          {
            label: '正常办理',
            value: 0
          }, {
            label: '停止办理',
            value: 1
          }
        ],
      }
    },

    async created() {
      this.cardId = this.$route.query.id
      this.Base_url = Base_url
      await this.getCard()
      await this.searchEvent()
    },

    methods: {
      async getCard() {
        let res = await this.$get("/admin/getCard", {id: this.cardId})
        if (res.code == 200) {
          this.item = res.mbCard
          if (res.mbCard.giveGoldDiscount == 1) {
            this.checked = true
          }
          this.cardOption = [...res.cardOptions]
          this.allCheckCities = [...res.departmentIds]
          this.checkedItemIds = [...this.item.itemIds]
          this.checkedSkuIds = [...res.skuIds]
        }
      },

      async searchEvent() {
        let checkTotal = 0;
        let cities = {};
        // let res = await getRegionList();
        let data = await this.$get("/admin/getDepartmens")
        let res = data
        for (let i = 0; i < res.length; i++) {
          let item = res[i];
          let sysDepartmentList = item['sysDepartmentList'];
          let ids = sysDepartmentList.map(sd => (sd.id));
          let checkedCities = this.allCheckCities.filter(item => {
            return ids.indexOf(item) > -1;
          });
          checkTotal += item['sysDepartmentList'].length;
          let checkAll = checkedCities.length === ids.length;
          cities[item.id] = {
            ids,
            checkAll,
            checkedCities,
            name: item.departmentName,
            list: item['sysDepartmentList']
          }
        }
        this.checkTotal = checkTotal;
        this.cities = cities;
      },
      // 监听 group 选择
      handleCheckedCitiesChange(key) {
        let item = this.cities[key];
        item.checkAll = item.ids.length === item.checkedCities.length;
        this.getCheckedLen()
      },
      // 监听全选
      handleCheckAllChange(key) {
        let item = this.cities[key];
        let checkAll = item.checkAll;
        item.checkedCities = checkAll ? item.ids : [];
        this.getCheckedLen()
      },
      checkAllEvent() {
        let cities = this.cities;
        if (this.checkAll) {
          for (let key in  cities) {
            let item = cities[key];
            item.checkedCities = item.ids;
            item.checkAll = true;
          }
        } else {
          for (let key in  cities) {
            let item = cities[key];
            item.checkAll = false;
            item.checkedCities = [];
          }
        }
        this.cities = cities;
        this.getAllCheckCityes();
      },
      getAllCheckCityes() {
        let allCheckCities = [];
        for (let key in  this.cities) {
          let item = this.cities[key];
          allCheckCities = [...allCheckCities, ...item.checkedCities]
        }
        this.allCheckCities = allCheckCities;
      },
      getCheckedLen() {
        let len = 0;
        for (let key in  this.cities) {
          let item = this.cities[key];
          len += item.checkedCities.length
        }
        if (this.checkTotal === len) {
          this.checkAll = true;
        } else {
          this.checkAll = false;
        }
        this.getAllCheckCityes();
      },


      //图片相关
      imageSuccess1(res) {
        this.imgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },

      setChecked() {
        this.itemIds = this.checkedItemIds
        this.skuIds = this.checkedSkuIds
        this.viewVisible = false
      },

      async getBsServiceItemList() {
        let res = await this.$get("/admin/getBsServiceItemList")
        if (res.code == 200) {
          this.serviceItemList = res.serviceItemList
        }
      },

      async getSkuList() {
        let res = await this.$get("/admin/getSkuList")
        if (res.code == 200) {
          this.skuList = res.skuList
        }
      },

      siView() {
        this.getBsServiceItemList()
        this.getSkuList()
        this.viewVisible = true
      },


      closeEvent() {
        this.$router.push("/baseData/card")
      },

      async saveMbCard() {
        if (!this.item.cardNo) {
          this.$message.error("请输入卡类编号")
          return
        }
        if (!this.item.cardName) {
          this.$message.error("请输入卡类名称")
          return
        }
        if (!this.item.openCardCost) {
          this.$message.error("请输入开卡成本")
          return
        }
        if (!this.item.openCardAmount) {
          this.$message.error("请输入开卡金额")
          return
        }
        if (!this.item.minimumRechargeAmount) {
          this.$message.error("请输入最低充值金额")
          return
        }
        if (!this.item.rechargeAmountReminder) {
          this.$message.error("请输入充值金额提醒")
          return
        }
        if (!this.item.itemDiscount) {
          this.$message.error("请输入项目折扣")
          return
        }
        if (!this.item.productDiscount) {
          this.$message.error("请输入卖品折扣")
          return
        }
        if (!this.item.noExceed) {
          this.$message.error("请输入赠送金不超过总金额的")
          return
        }
        if (!this.item.minimumBalance) {
          this.$message.error("请输入卡内最低余额")
          return
        }
        if (!this.item.cdNotExceed) {
          this.$message.error("请输入开卡当日消费不得超过总额的")
          return
        }
        if (!this.item.noBelow) {
          this.$message.error("请输入卡金支付额不得低于账单金额的")
          return
        }
        if (!this.item.expiryDate) {
          this.$message.error("请输入有效期")
          return
        }

        if (this.checked) {
          this.item.giveGoldDiscount = 1
        } else {
          this.item.giveGoldDiscount = 0
        }
        let data = {
          id: this.item.id,
          cardNo: this.item.cardNo,
          cardName: this.item.cardName,
          cardType: this.item.cardType,
          openCardCost: this.item.openCardCost,
          openCardAmount: this.item.openCardAmount,
          minimumRechargeAmount: this.item.minimumRechargeAmount,
          rechargeAmountReminder: this.item.rechargeAmountReminder,
          cardOption: JSON.stringify(this.cardOption),
          itemDiscount: this.item.itemDiscount,
          productDiscount: this.item.productDiscount,
          amountReminderSms: this.item.amountReminderSms,
          isSmsFee: this.item.isSmsFee,
          cardNumber: this.item.cardNumber,
          isHandle: this.item.isHandle,
          overdueConsumption: this.item.overdueConsumption,
          crossStoreConsumption: this.item.crossStoreConsumption,
          crossStoreRecharge: this.item.crossStoreRecharge,
          storeClosedRecharge: this.item.storeClosedRecharge,
          kaginGiveMerge: this.item.kaginGiveMerge,
          giveGoldDiscount: this.item.giveGoldDiscount,
          noExceed: this.item.noExceed,
          minimumBalance: this.item.minimumBalance,
          cdNotExceed: this.item.cdNotExceed,
          noBelow: this.item.noBelow,
          expiryDate: this.item.expiryDate,
          remarks: this.item.remarks,
          noDiscount: this.item.noDiscount,
          imgUrl: this.item.imgUrl,
          cardNumberType: this.item.cardNumberType,
          itemIdStr: JSON.stringify(this.itemIds),
          skuIdStr: JSON.stringify(this.skuIds),
          departmentIdStr: JSON.stringify(this.allCheckCities)
        };

        // if(!this.imgUrl) {
        //     this.$message.error("请上传图片")
        //     return
        // }
        let res = await this.$post("/admin/save_mbCard", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push("/baseData/card")
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>

<style>
  .p-text {
    margin-left: 10px;
    height: 20px
  }

  .el-radio__inner {
    border: 1px solid #DCDFE6;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    background-color: #FFFFFF;
    position: relative;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
  }
</style>
