<template>
  <div>
    <div class="e-breadcrumb">会员卡设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">会员卡
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">卡类型积分规则
              </div>
            </div>
          </div>
          <TabCard  v-if="tabIndex==0"/>
          <TabIntegral  v-if="tabIndex==1"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabCard from './card/entry.vue'
  import TabIntegral from './integral/entry.vue'

  export default {
    data() {
      return {
        tabIndex:0,
        item: {},
      }
    },
    async created() {
    },
    methods: {

      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabCard,
      TabIntegral,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
