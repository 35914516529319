<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">

          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">卡类编号:</span>
              <el-input placeholder="卡类编号" size="small" style="width:265px" v-model="cardNo" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">卡类名称:</span>
              <el-input placeholder="卡类名称" size="small" style="width:265px" v-model="cardName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">卡类型:</span>
              <el-select size="small" clearable v-model="cardType" style="width: 265px;" placeholder="请选择">
                <el-option
                  v-for="item in cardList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <!--<div style="margin-right: 37px">-->
              <!--<el-row type="flex" justify="end">-->
                <!--<el-button size="small" class="e-button" type="primary" @click="searchEvent">查询-->
                <!--</el-button>-->
                <!--<el-button size="small" class="e-button" type="success" @click="addEvent()">新增-->
                <!--</el-button>-->
              <!--</el-row>-->
            <!--</div>-->
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="80" class="th-l">卡类编号</th>
                <th width="130" class="th-l">卡类型名称</th>
                <th width="130" class="th-l">卡类型</th>
                <th width="130" class="th-l">折扣</th>
                <th width="130" class="th-l">普通消费积分规则</th>
                <th width="130" class="th-l">客带客消费积分规则</th>
                <th width="130" class="th-l">客带客充值积分规则</th>
                <th width="160" class="th-l">创建时间</th>
                <!--<th width="200" class="th-l">操作</th>-->
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.cardNo}}</span></td>
                <td class="td-l"><span>{{row.cardName}}</span></td>
                <td class="td-l">
                  <span v-if="row.cardType==0">储值卡</span>
                  <span v-if="row.cardType==1">计次卡</span>
                  <span v-if="row.cardType==2">套餐卡</span>
                  <span v-if="row.cardType==3">年卡</span>
                  <span v-if="row.cardType==4">资格卡</span>
                </td>
                <td class="td-l">{{row.productDiscount}}</td>
                <td class="td-l">

                </td>
                <td class="td-l">

                </td>

                <td class="td-l">

                </td>
                <td class="td-l"><span>{{row.createTime}}</span></td>
                <!--<td class="td-l">-->
                  <!--<el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>-->
                  <!--<el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>-->
                <!--</td>-->
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Insert from './insert'
  import Edit from './edit'

  export default {
    data() {
      return {
        id: '',
        cardNo: '',
        cardName: '',
        cardType: '',
        isHandle: '',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        delVisible: false,
        editItem: {},
        cardList: [{
          value: '0',
          name: '储值卡'
        }, {
          value: '1',
          name: '计次卡'
        }, {
          value: '2',
          name: '套餐卡'
        }, {
          value: '3',
          name: '年卡'
        }, {
          value: '4',
          name: '资格卡'
        },{
            value: '5',
            name: '散客卡'
          }
        ],
        isHandleList: [{
          value: '0',
          name: '正常办理'
        }, {
          value: '1',
          name: '停止办理'
        }
        ],
      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {
      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          cardNo: this.cardNo,
          cardName: this.cardName,
          cardType: this.cardType,
          isHandle: this.isHandle,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getMbCardForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      addEvent() {
        this.$router.push("/baseData/saveCard")
      },

      editEvent(index) {
        this.editItem = this.list[index]
        this.$router.push("/baseData/updateCard?id=" + this.list[index].id)
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除MbCard
      async delMbCard() {
        let data = {
          id: this.editItem.id
        };

        let res = await deleteMbCard(data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

    },
    components: {
      Insert,
      Edit
    }
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
